import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'

const FeaturedServices = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="features" /> 
                        Our Services
                    </span>
                    <h2>Our Featured Services</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Staging Consultation
                                </Link>
                            </h3>
                            <p>All Occupied homes start with a Staging Consultation. This Service provides valuable feedback on how to best showcase the potential of the home and get it ready for market. Each room is assessed with specific recommendations on what should be addressed, ie: Updating, paint, furniture removal, rearrangement, etc. After the initial consultation, should the seller feel that they need additional hands on staging, White Orchid will provide a quote to stage the property. We work with existing furnishings whenever possible, but may suggest bringing new updated pieces to “pull” it all together.</p>

                            <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Vacant Staging
                                </Link>
                            </h3>
                            <p>Transforms property into a  showplace with the careful selection of furnishings, art and accessories. Every home is unique in its character, therefore, should be staged as such.  With a wide array of furnishings, art and accessories to choose from, we have the ability to stay current and on trend.</p>

                            <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="features" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Interior Re-Design
                                </Link>
                            </h3>
 
                            <p>White Orchid can breathe new life into tired spaces by using your existing furnishings, accessories, and art. We create a fresh new environment that illuminates warmth, beauty and style. If your vision and budget includes new pieces, we delight in creating styles that are current and reflect your personality. Our Re-Design service can develop a decorating plan and then shop for those important pieces and accessories that will help create the desired overall look of your space.</p>

                            <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedServices