import React from 'react'
import client1 from '../../assets/images/user1.jpg'
import client2 from '../../assets/images/user2.jpg'
import client3 from '../../assets/images/user3.jpg'
import shape9 from '../../assets/images/shape/vector-shape9.png'
import shape10 from '../../assets/images/shape/vector-shape10.png'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <section className="testimonials-area bg-23173a pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>What Our Clients are Saying?</h2>
                    <p>5-Star Testimonials for JJStaging</p>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-testimonials-item">
                        <p>I started teaming up with JJStaging back in 2020. Rebecca is my Property Stylist of choice. She works with any style of architecture. She and her crew create a visional lifestyle that appeals to today's buyers. They are all extremely professional and pleasure to work with!</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client1} alt="testimonial" />
                                <div className="title">
                                    <span>Compass | Real Estate Agent</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>If you are looking to have a stress free experience with a lifestyle concierge professional, then look no further than Jing & Jane. Everything was taken care of, even things we hadn't requested. Rebecca's attention to detail & genuine style was reflective throughout the design process.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client2} alt="testimonial" />
                                <div className="title">
                                    <span>Homeowner</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Fun, creative and reliable team of designers that delivery a timeless and polished look every time. Rebecca gives a great color consultation, her redesign work is superb and her staging is always current with what buyers want. I recommend JJStaging for any property styling/home staging and redesign needs. </p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client3} alt="testimonial" />
                                <div className="title">
                                    <span>Real Estate Agent</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}
            </div>

            <div className="vector-shape9">
                <img src={shape9} alt="shape" />
            </div>
            <div className="vector-shape10">
                <img src={shape10} alt="shape" />
            </div>
        </section>
    )
}

export default Testimonials