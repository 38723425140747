import React from 'react'
import ReactWOW from 'react-wow'
import remax from '../../assets/images/partner/remax.png'
import compass from '../../assets/images/partner/compass_logo.jpg'
import coldwellbanker from '../../assets/images/partner/coldwellbanker.png'
import JingXue from '../../assets/images/partner/logo_new.jpg'

const Partner = () => {
    return (
        <div className="partner-area pt-100 pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={remax} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={compass} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={coldwellbanker} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partner